



























































import { defineComponent } from "@vue/composition-api";
import { Button, Cell, CellGroup, Field, Form, Toast, Uploader, Icon, Image, Dialog, ImagePreview } from "vant";
import axios from "@/helpers/axios";
import * as qiniu from "qiniu-js";

export default defineComponent({
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Image.name]: Image
  },
  data() {
    return {
      maxFileSize: 5, //mb
      qiniuToken: "",
      imageList: [] as { file: File; status: string; key: string }[],
      amount: "" as number | string,
      applyInfo: {},
      introImageUrl: "https://resource.letfungo.com/账号确认步骤2.gif",
      understandBtnLoading: true,
      understand: false
    };
  },
  created() {
    this.$axios.post("/api/manage/getQiniuToken", {}).then(res => {
      this.qiniuToken = res.data.data;
    });
    this.getApplyInfo();
  },
  methods: {
    async getApplyInfo() {
      const result = await axios
        .post("/api/manage/review/applyInfoAccountConfirm")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!result) return;
      this.applyInfo = result;
    },
    async submit() {
      if (Number(this.amount) < 0.01) {
        Toast.fail("请正确填写金额");
        return;
      }
      if (Number(this.amount) > 2) {
        Toast.fail("金额不合法");
        return;
      }
      if (this.imageList.length != 1 || !this.imageList[0].key) {
        Toast.fail("请上传到账截图");
        return;
      }
      const result = await axios
        .post("/api/manage/review/applyForAccountConfirm", {
          amount: Math.round(Number(this.amount) * 100),
          imageUrl: this.imageList[0].key
        })
        .then(() => true)
        .catch(() => false);
      if (!result) return;
      Toast.success("提交成功");
      this.getApplyInfo();
    },
    async initCheckOrder() {
      const result = await axios
        .post("/api/manage/review/initCheckOrderForAccountConfirm")
        .then(() => true)
        .catch(() => false);
      if (!result) return;
      this.getApplyInfo();
    },
    confirmUnderstand() {
      Dialog.confirm({
        message: "您确定您已经理解并可以按照图示说明操作了吗? 操作不当可能给您造成不必要的延误",
        confirmButtonText: "我已理解",
        confirmButtonColor: "red"
      }).then(() => {
        this.understand = true;
      });
    },
    showIntroImage() {
      ImagePreview({ images: [this.introImageUrl], closeable: true });
    },
    readyUnderstandButton() {
      setTimeout(() => {
        this.understandBtnLoading = false;
      }, 5100);
    },
    onFileOversize() {
      Toast.fail("文件大小不能超过" + this.maxFileSize + "MB");
    },
    onPickFile(file: { file: File; status: string; key: string }) {
      file.status = "uploading";
      const observable = qiniu.upload(file.file as File, null, this.qiniuToken);
      observable.subscribe({
        complete: res => {
          file.status = "done";
          file.key = res.key;
        },
        error: () => {
          file.status = "error";
        }
      });
    }
  }
});
